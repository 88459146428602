import Utils from "../../scripts/files/utils.js";

export default function OrderCard() {
    const utils = new Utils();
    const orderHistoryContainers = document.querySelectorAll('[data-order-history="container"]');
    let body = document.body;

    if (orderHistoryContainers) {

        orderHistoryContainers.forEach(orderHistoryContainer => {
            let orderHistoryBtn = orderHistoryContainer.querySelector('[data-order-history="button"]');
            let orderHistoryBlock = orderHistoryContainer.querySelector('[data-order-history="block"]');

            if (orderHistoryBtn){
                orderHistoryBtn.addEventListener("click", (e) => {
                    if(!orderHistoryBlock.classList.contains('_slide')){

                        utils.slideToggle(orderHistoryBlock);

                        if (orderHistoryContainer.classList.contains('order-card--open')){
                            orderHistoryBtn.classList.remove('button--orange');
                            orderHistoryBtn.innerHTML = "Посмотреть статус";

                            setTimeout(function() {
                                orderHistoryContainer.classList.remove('order-card--open');
                            }, 500);
                        }
                        else{
                            orderHistoryContainer.classList.add('order-card--open');
                            orderHistoryBtn.classList.add('button--orange');
                            orderHistoryBtn.innerHTML = "Свернуть";
                        }
                    }
                });
                body.addEventListener('click', function (e) {
                    if (e.target.closest('.order-card') !== orderHistoryContainer) {
                        if(!orderHistoryBlock.classList.contains('_slide')){
                            utils.slideUp(orderHistoryBlock);
                            orderHistoryBtn.classList.remove('button--orange');
                            orderHistoryBtn.innerHTML = "Посмотреть статус";

                            setTimeout(function() {
                                orderHistoryContainer.classList.remove('order-card--open');
                            }, 500);
                        }
                    }
                });
            }
        });
    }
}