export default function Header(){
    console.log('HEADER BLOCK IS LOADED ON BOTTOM');

    let contactsOpenBtns = document.querySelectorAll('[data-contacts-mobile="open"]');
    let contactsPopup = document.querySelector('[data-contacts-mobile="popup"]');
    let body = document.body;
    if (contactsOpenBtns.length > 0 && contactsPopup) {
        contactsOpenBtns.forEach(contactsOpenBtn => {
            contactsOpenBtn.addEventListener('click', function (e) {
                contactsPopup.classList.toggle('contacts-mobile--open');
                contactsOpenBtn.classList.toggle('active');
                e.preventDefault();
            });
            body.addEventListener('click', function (e) {
                if (!e.target.closest('.contacts-mobile') && !e.target.closest('[data-contacts-mobile="open"]')) {
                    contactsPopup.classList.remove('contacts-mobile--open');
                    contactsOpenBtn.classList.remove('active');
                }
            });
        });
    }
}