import Swiper from "swiper/bundle";

export default function SectionsCarousel(){

    let filterSwiperContainer = document.querySelector('[data-swiper="sections-carousel"]');

    if(filterSwiperContainer){

        let filterSwiper =  new Swiper(filterSwiperContainer, {
            modules: [],
            slidesPerView:2,
            spaceBetween: 15,
            grid: {
                fill:'row',
            },
            pagination: {
                clickable: true,
                el: ".sections-carousel__pagination",
            },
            navigation: {
                nextEl: '.sections-carousel__button--next',
                prevEl: '.sections-carousel__button--prev',
            },
            breakpoints: {
                280: {
                    slidesPerView:2,
                    spaceBetween: 15,
                    slidesPerGroup:2,
                    grid: {
                        rows: 2,
                    },
                },
                340: {
                    slidesPerView:3,
                    spaceBetween: 10,
                    slidesPerGroup:2,
                    grid: {
                        rows: 2,
                    },
                },
                576: {
                    slidesPerView:3,
                    spaceBetween: 15,
                    slidesPerGroup:2,
                    grid: {
                        rows: 2,
                    },
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    slidesPerGroup:2,
                    loop: true,
                    grid: {
                        rows: 1,
                    },
                },
                992: {
                    slidesPerView: 6,
                    slidesPerGroup:3,
                    loop: true,
                    grid: {
                        rows: 1,
                    },
                },
                1200: {
                    slidesPerView: 7,
                    slidesPerGroup:3,
                    loop: true,
                    grid: {
                        rows: 1,
                    },
                },
                1400: {
                    slidesPerView: 8,
                    spaceBetween: 20,
                    slidesPerGroup:4,
                    loop: true,
                    grid: {
                        rows: 1,
                    },
                }
            }
        });
    }
}