import Utils from "../../scripts/files/utils.js";

export default function Menu(){
    const utils = new Utils();
    let body = document.body;
    const header = document.querySelector('header');
    const menu_btn = document.querySelector('[data-menu-btn]');
    const menu = document.querySelector('[data-menu]');
    const menu_l1_items = document.querySelectorAll('.menu__l1 > ul > li');
    const menu_l2 = document.querySelector('.menu__l2');
    const menu_l2_inner = document.querySelector('.menu__l2-inner');
    const menu_img_banner = document.querySelector('.menu__l2-banner img');

    if(header){

        body.addEventListener('click', function (e){
            if (!e.target.closest('header') && !e.target.closest('[data-menu]')) {
                if (header.classList.contains('header--active')){
                    header.classList.toggle('header--active');
                    menu_btn.classList.toggle('header__shop-btn--active');
                    menu.classList.toggle('menu--active');
                }
            }
        });

        menu_btn.addEventListener("click", (event) => {
            header.classList.toggle('header--active');
            menu_btn.classList.toggle('header__shop-btn--active');
            menu.classList.toggle('menu--active');
        });

        menu_l1_items.forEach( element => {
            element.addEventListener("mouseenter", (event) => {
                let img = event.target.dataset.menuImg;
                let parent = element.closest("ul > li ");
                let current_menu = parent.querySelector('ul');

                if (current_menu != null){
                    menu_l2_inner.innerHTML = current_menu.innerHTML;
                    menu_img_banner.setAttribute('src', img);
                    menu_l2.classList.add('menu__l2--active');
                } else {
                    menu_l2.classList.remove('menu__l2--active');
                }
            });

            element.addEventListener('click', (e) => {
                if (menu.classList.contains('menu--mobile-active')){

                    let current_menu = element.querySelector('ul');

                    if(!current_menu.classList.contains('_slide')){
                        element.classList.toggle('menu__l1-item--active');

                        if (current_menu != null){
                            utils.slideToggle(current_menu);
                            current_menu.classList.toggle('menu__l2-mobile');
                        }

                        menu_l1_items.forEach( item => {
                            if (item !== element){
                                item.classList.remove('menu__l1-item--active');

                                current_menu = item.querySelector('ul');

                                if (current_menu != null){
                                    utils.slideUp(current_menu);
                                    current_menu.classList.remove('menu__l2-mobile');
                                }
                            }
                        })
                    }
                }
            })
        })
    }
}