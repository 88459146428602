export default function ProductHeader(){

    //Выпадающая шапка при скролле вниз
    let product_header = document.querySelector('[data-product-header]');
    let product_header_anchor = document.querySelector('[data-product-header-anchor]');

    if (product_header && product_header_anchor) {
        window.addEventListener('scroll', function() {
            if(product_header_anchor.getBoundingClientRect().bottom < 0){
                product_header.classList.add('product-header--active');
            }
            else{
                product_header.classList.remove('product-header--active');
            }
        });
    }
}