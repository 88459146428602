import Swiper from "swiper/bundle";

export default function mainSlider(){
    let swiper;
    return swiper = new Swiper('[data-swiper="main-slider"]', {
        modules: [],
        loop: true,
        pagination: {
            clickable: true,
            el: ".main-slider__pagination",
        },
        navigation: {
            nextEl: '.main-slider__button--next',
            prevEl: '.main-slider__button--prev',
        },
    });
}