import Swiper from "swiper/bundle";

export default function DayProducts (){
    let swiper;

    return swiper = new Swiper('[data-swiper="day-products"]', {
        modules: [],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            clickable: true,
            el: ".day-products__pagination",
        },
    });
}