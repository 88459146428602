
export default function LkProfile(){

    let profileImage = document.querySelector('[data-img="lk-profile-photo"]');
    let profileInputImage = document.querySelector('[data-input="lk-profile-photo"]');
    let changeProfileButtons = document.querySelectorAll('[data-profile="change-data"]');

    let surname = document.querySelector('[data-profile-text="surname"]');
    let name = document.querySelector('[data-profile-text="name"]');
    let phone = document.querySelector('[data-profile-text="phone"]');
    let date = document.querySelector('[data-profile-text="date"]');

    let surnameInput = document.querySelector('[data-profile-input="surname"]');
    let nameInput = document.querySelector('[data-profile-input="name"]');
    let phoneInput = document.querySelector('[data-profile-input="phone"]');
    let dateInput = document.querySelector('[data-profile-input="date"]');

    if (profileImage && profileInputImage) {

        changeProfileButtons.forEach(changeProfileButton => {
            changeProfileButton.addEventListener('click', function(){
                surnameInput.value = surname.innerHTML.trim();;
                nameInput.value = name.innerHTML.trim();
                phoneInput.value = phone.innerHTML.trim().substring(2);
                dateInput.value = date.innerHTML.trim();
            });
        })

        profileInputImage.addEventListener('change', function(){
            profileImage.src = URL.createObjectURL(profileInputImage.files[0]);
        });
    }
}