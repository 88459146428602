// ==== Popup form handler====
export default function Location() {

	let locationPopup = document.querySelector('[data-location="popup"]');

	if (locationPopup) {
		let locationListContainer = locationPopup.querySelector('[data-location="list"]');

		if (locationListContainer) {
			let locationList = locationListContainer.querySelectorAll('li');

			if (locationList.length > 0) {
				locationList.forEach(locationListItem => {

					locationListItem.addEventListener('click', function (e) {
						if (!locationListItem.classList.contains('location__list-item--selected')) {
							locationListItem.classList.add('location__list-item--selected');

							locationList.forEach(i => {
								if(i === locationListItem)
									return
								i.classList.remove('location__list-item--selected');
							})
						}
						locationPopup.classList.remove('popup--open');
					});
				})
			}
		}
	}
}