import Utils from "../../scripts/files/utils.js";

export default function CatalogSort() {
    const utils = new Utils();
    const catalog_sort = document.querySelector('[data-catalog-sort]');
    const catalog_sort_current = document.querySelector('.catalog-sort__current-item');
    const catalog_sort_dropdown = document.querySelector('[data-catalog-sort-dropdown]');
    const catalog_sort_items = document.querySelectorAll('.catalog-sort__item');
    let body = document.body;

    if (catalog_sort) {
        catalog_sort.addEventListener('click', (e) => {
            if(!catalog_sort_dropdown.classList.contains('_slide')){
                catalog_sort.classList.toggle('catalog-sort--active');
                catalog_sort_dropdown.classList.toggle('catalog-sort__dropdown--active');
                utils.slideToggle(catalog_sort_dropdown, 300);
            }
        });

        body.addEventListener('click', function (e) {
            if (!e.target.closest('[data-catalog-sort]')) {
                if(!catalog_sort_dropdown.classList.contains('_slide')){
                    catalog_sort.classList.remove('catalog-sort--active');
                    catalog_sort_dropdown.classList.remove('catalog-sort__dropdown--active');
                    utils.slideUp(catalog_sort_dropdown, 300);
                }
            }
        });

        catalog_sort_items.forEach(element => {
            element.addEventListener("click", (event) => {

                catalog_sort_items.forEach((e) => {
                    e.classList.remove('catalog-sort__item--active');
                })

                event.target.classList.add('catalog-sort__item--active');
                catalog_sort_current.textContent = event.target.textContent;
            });
        });
    }
}