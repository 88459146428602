
export default function ImageCart(){

    let imageCartContainers = document.querySelectorAll('[data-image-cart-container]');
    if (imageCartContainers.length) {
        imageCartContainers.forEach(imageCartContainer => {
            let imageCartItem = imageCartContainer.querySelectorAll('[data-image-cart-item]');

            if (imageCartItem.length) {
                imageCartItem.forEach(item => {
                    item.addEventListener('click', () => {
                        item.classList.add('tab-active');

                        imageCartItem.forEach(i => {
                            if (i === item) return;

                            i.classList.remove('tab-active');
                        })
                    })
                })
            }
        })
    }
}