import Utils from "../../scripts/files/utils.js";

export default function MobileBottomMenu() {
    const utils = new Utils();
    const header = document.querySelector('.header');
    const menu_outer = document.querySelector('.menu__outer');
    const menu = document.querySelector('[data-menu]');
    const catalog_btn = document.querySelector('[data-mobile-bottom-menu="catalog"]');
    const body = document.querySelector('body');
    const close_menu_btn = document.querySelector('[data-close-mobile-menu]');
    const menu_l1_items = document.querySelectorAll('.menu__l1 > ul > li');

    if(header) {
        catalog_btn.addEventListener('click', (e) => {
            e.preventDefault();
            toggleCatalogMenu();
        });

        close_menu_btn.addEventListener('click', (e) => {
            e.preventDefault();
            toggleCatalogMenu();
        })

        function toggleCatalogMenu() {
            header.classList.toggle('header--mobile-active');
            body.classList.toggle('body--fixed');
            menu_outer.classList.toggle('menu__outer--mobile');
            menu.classList.toggle('menu--mobile-active');
            catalog_btn.classList.toggle('mobile-bottom-menu__item--active');

            if(!catalog_btn.classList.contains('mobile-bottom-menu__item--active')){

                let current_menu;
                menu_l1_items.forEach( item => {
                    item.classList.remove('menu__l1-item--active');

                    current_menu = item.querySelector('ul')

                    if (current_menu != null){
                        utils.slideUp(current_menu);
                        current_menu.classList.remove('menu__l2-mobile');
                    }
                })
            }
        }
    }
}