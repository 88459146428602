
export default function Lk(){

    let lk = document.querySelector('.lk');
    let footer = document.querySelector('footer');
    let header = document.querySelector('header');

    if (lk) {
        header.classList.add("d-none", "d-md-flex");
        footer.classList.add("d-none", "d-md-block");
    }

}