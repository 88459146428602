import Header from "../blocks/header/header.js";
import mainSlider from "../blocks/main-slider/main-slider.js";
import CatalogCarousel from "../blocks/catalog-carousel/catalog-carousel.js";
import SectionsCarousel from "../blocks/sections-carousel/sections-carousel.js";
import DayProducts from "../blocks/day-products/day-products.js";
import Menu from "../blocks/menu/menu.js";
import MobileBottomMenu from "../blocks/mobile-bottom-menu/mobile-bottom-menu.js";
import CatalogFilter from "../blocks/catalog-filter/catalog-filter.js";
import ProductPreview from "../blocks/product-preview/product-preview.js";
import ProductDetails from "../blocks/product-details/product-details.js";
import CatalogSort from "../blocks/catalog-sort/catalog-sort.js";
import ProductHeader from "../blocks/product-header/product-header.js";
import PriceRange from "../blocks/price-range/price-range.js";
import ImageCart from "../blocks/image-cart/image-cart.js";
import OrderPaymentCarousel from "../blocks/order/order.js";
import tabsInit from "../blocks/tabs/tabs.js";
import Input from "../blocks/input/input.js";
import popup from "../blocks/popup/popup.js";
import confirmLocation from "../blocks/confirm-location/confirm-location.js";
import Location from "../blocks/location/location.js";
import LkProfile from "../blocks/lk-profile/lk-profile.js";
import Lk from "../blocks/lk/lk.js";
import OrderCard from "../blocks/order-card/order-card.js";

//common
Header();
Menu();
MobileBottomMenu();
Input();
tabsInit();
popup();
confirmLocation();
Location();

//main page
mainSlider();
CatalogCarousel();
SectionsCarousel();
DayProducts();

//catalog
CatalogFilter();
CatalogSort();
PriceRange();

//product-cart
ProductPreview();
ProductDetails();
ProductHeader();

//order
ImageCart();
OrderPaymentCarousel();

//lk
Lk();
LkProfile();
OrderCard();

