import Swiper from "swiper/bundle";
import Inputmask from "inputmask/bundle";

export default function OrderPaymentCarousel(){
    let swiper;

    let items = document.querySelectorAll('[data-mask]');
    if (items.length) {
        items.forEach(item => {
            let maskValue = item.dataset.mask;
            let input = item.querySelector('input');

            if (input) {
                new Inputmask(maskValue, {
                    //"placeholder": '',
                    clearIncomplete: true,
                    clearMaskOnLostFocus: true,
                    showMaskOnHover: false
                }).mask(input);
            }
        })
    }

    //Проверка полей на заполненность. data-btn-check необходимо указать у кнопки которая будет недоступна пока все поля с data-check-group не будут заполнены.
    let buttonsCheckField = document.querySelectorAll('[data-btn-check]');


    if (buttonsCheckField.length) {

        buttonsCheckField.forEach(buttonCheckField => {
            let checkFields = document.querySelectorAll('[data-check-group="'+ buttonCheckField.dataset.btnCheck +'"]');
            let flag = false;

            checkFields.forEach(checkField => {

                let handler = function (){

                    flag = false;
                    checkFields.forEach(field => {
                        if (field.value ==='')
                            flag = true;
                        else
                            switch (field.getAttribute('type')) {
                                case 'email':
                                    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
                                    if(!EMAIL_REGEXP.test(field.value))
                                        flag = true;
                                    break;
                                case 'tel':
                                    const PHONE_REGEXP = /\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/;
                                    if(!PHONE_REGEXP.test(field.value))
                                        flag = true;
                                    break;
                                case 'date-custom':
                                    const DATE_REGEXP = /\d{2}.\d{2}.\d{4}/;
                                    if(!DATE_REGEXP.test(field.value))
                                        flag = true;
                                    break;
                            }
                    })

                    if (flag){
                        buttonCheckField.classList.add('button--disabled');
                        buttonCheckField.setAttribute('disabled','disabled');
                    }else{
                        buttonCheckField.classList.remove('button--disabled');
                        buttonCheckField.removeAttribute('disabled','disabled');
                    }
                }

                checkField.addEventListener('keyup', handler);
                //checkField.addEventListener('change', handler);
            })
        });
    }




    return swiper = new Swiper('[data-swiper="payment-carousel"]', {
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        speed: 800,
        watchOverflow: true,
        watchSlidesVisibility: true,
        freeMode: true,
        slideToClickedSlide: true,
        spaceBetween: 5,
         breakpoints: {
             768: {
                 spaceBetween: 15,
             },
         },
    });
}