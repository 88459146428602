import Swiper from "swiper/bundle";

export default function CatalogCarousel (){
    let swiper;

    return swiper = new Swiper('[data-swiper="catalog-carousel"]', {
        modules: [],
        loop: true,
        slidesPerView: 2.2,
        spaceBetween: 20,
        navigation: {
            nextEl: '.catalog-carousel__button--next',
            prevEl: '.catalog-carousel__button--prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            992: {
                slidesPerView: 4
            },
            1200: {
                slidesPerView: 5
            },
            1400: {
                slidesPerView: 6
            }
        }
    });
}