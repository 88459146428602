// ==== Popup form handler====
export default function confirmLocation() {

	let locationCloseBtns = document.querySelectorAll('[data-confirm-location="close"]');
	let locationPopup = document.querySelector('[data-confirm-location="popup"]');

	if (locationPopup) {

		locationPopup.addEventListener('click', function (e) {
			if (!e.target.closest('[data-confirm-location="content"]')) {
				locationPopup.classList.remove('confirm-location--open');
			}
		});

		if (locationCloseBtns.length > 0) {
			locationCloseBtns.forEach(locationCloseBtn => {
				locationCloseBtn.addEventListener('click', function (e) {
					locationCloseBtn.closest('[data-confirm-location="popup"]').classList.remove('confirm-location--open');
				});
			});
		}
	}
}