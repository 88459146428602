import Swiper from "swiper/bundle";

export default function CatalogFilter(){
    const filter_show_btn = document.querySelector('[data-filter-show]');
    const filter_hide_btn = document.querySelector('[data-filter-hide]');
    const catalog_filter = document.querySelector('[data-catalog-filter]');
    const body = document.querySelector('body');

    if (filter_show_btn) {
        filter_show_btn.addEventListener('click', (e) => {
            catalog_filter.classList.add('catalog-filter--visible');
            body.classList.add('overflow-hidden');
        });
    }

    if (filter_hide_btn) {
        filter_hide_btn.addEventListener('click', (e) => {
            catalog_filter.classList.remove('catalog-filter--visible');
            body.classList.remove('overflow-hidden');
        });
    }

    let filterSwiperContainer = document.querySelector('[data-swiper="filter-selected"]');

    if(filterSwiperContainer){
        let filterSwiper;

        function mobileSlider() {

            if (document.documentElement.clientWidth <= 767) {
                if (!filterSwiperContainer.classList.contains('swiper-initialized')) {
                    filterSwiper =  new Swiper(filterSwiperContainer, {
                        observer: true,
                        observeParents: true,
                        slidesPerView: 'auto',
                        speed: 800,
                        watchOverflow: true,
                        watchSlidesVisibility: true,
                        freeMode: true,
                        slideToClickedSlide: true,
                        spaceBetween: 5,
                        breakpoints: {
                            768: {
                                spaceBetween: 10,
                            },
                        },
                    });
                }
            }

            if (document.documentElement.clientWidth > 767) {
                if (filterSwiperContainer.classList.contains('swiper-initialized')) {
                    filterSwiper.destroy();
                }
            }
        }

        mobileSlider();

        window.addEventListener('resize', () => {
            mobileSlider();
        })


    }
}